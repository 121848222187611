<template>
  <div class="m-2">
   <b-card>
    <feather-icon
    icon="CalendarIcon"
    size="18"
    class="text-muted cursor-pointer"
  />
  <span>
    <b-dropdown
      no-caret
      right
      :text="datelabel"
      variant="transparent"
      class="chart-dropdown"
      toggle-class="p-50"
      size="sm"
    >
      <b-dropdown-item @click="selectdate(0)">วันนี้</b-dropdown-item>
      <b-dropdown-item @click="selectdate(1)">เมื่อวาน</b-dropdown-item>
      <b-dropdown-item @click="selectdate(2)">7 วันที่ผ่านมา</b-dropdown-item>
      <b-dropdown-item @click="selectdate(3)"
        >30 วันที่ผ่านมา</b-dropdown-item
      >
      <b-dropdown-item @click="selectdate(4)">เดือนนี้</b-dropdown-item>
      <b-dropdown-item @click="selectdate(5)">กำหนดเอง</b-dropdown-item>
    </b-dropdown>

    <hr />
    <b-form-group v-if="customdate">
      <h5>ระบุช่วงวันที่</h5>
      <flat-pickr
        v-model="rangeDate"
        class="form-control"
        :config="{ mode: 'range' }"
        @input="onDateChange"
      />
    </b-form-group>

    <p v-if="fromDate">ตั้งแต่ {{ fromDate }} ถึง {{ toDate }}</p>
  </span>

 
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mr-1 mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">none</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="Filter"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <br />
          จำนวนทั้งหมด {{ totalRows }} ตัว
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          tytr
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </div>
 
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardBody,
  BProgress,
  BBadge,
  BFormGroup,
  BImg,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BInputGroupAppend,
  BButton,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardBody,
    BProgress,
    BBadge,
    BFormGroup,
    BImg,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    flatPickr,
    BTable,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      datelabel: "7 วันที่ผ่านมา",
      customdate: false,
      fromDate: null,
      toDate: null,
      daterange: 1,
      rangeDate: null,
      Loading: true,
      filterOn: [],
      fields: [
        { key: "title", label: "venue" },
        { key: "AP", label: "AP" },
        { key: "Gateway", label: "Gateway" },
        { key: "connect", label: "session", sortable: true  },
        // { key: "sessions", label: "session", sortable: true },
        { key: "user", label: "user", sortable: true },
        { key: "Adddate", label: "Adddate", sortable: true },
        { key: "Online", label: "Gateway On", sortable: true },
        { key: "Offline", label: "Gateway Off", sortable: true },
        { key: "ApOffline", label: "AP On", sortable: true },
        { key: "ApOnline", label: "AP Off", sortable: true },
      ],
      /* eslint-disable global-require */
      items: [],
      perPage: 500,
      pageOptions: [ 500,750,1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
  
    };
  },
  beforeMount() {
    this.selectdate(this.daterange);
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  methods: {
    onDateChange() {
      console.log(this.rangeDate);
      if (this.rangeDate.search("to") > 0) {
        const myArr = this.rangeDate.split(" to ");
        this.fromDate = myArr[0];
        this.toDate = myArr[1];
        // this.$nextTick(() => {
        this.loadData();
        // });

        console.log(this.fromDate);
        console.log(this.toDate);
      }
    },

    selectdate(daterange) {
      this.loaded = false;
      this.daterange = daterange;
      // alert(daterange);
      var today = this.$moment();

      //  console.log(daterange);
      if (daterange == 0) {
        this.customdate = false;
        this.datelabel = "วันนี้";
        this.fromDate = today.format("YYYY-MM-DD");
        this.toDate = today.format("YYYY-MM-DD");
        // this.$nextTick(() => {
        this.loadData();
        // });
      } else if (daterange == 1) {
        this.customdate = false;
        this.datelabel = "เมื่อวาน";

        this.fromDate = today.subtract(1, "days").format("YYYY-MM-DD");
        this.toDate = this.fromDate;
        // this.$nextTick(() => {
        this.loadData();
        // });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 2) {
        this.datelabel = "7 วันที่ผ่านมา";

        this.fromDate = today.subtract(7, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        // this.$nextTick(() => {
        this.loadData();
        // });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 3) {
        this.customdate = false;

        this.datelabel = "30 วันที่ผ่านมา";

        this.fromDate = today.subtract(30, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        // this.$nextTick(() => {
        this.loadData();
        // });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 4) {
        this.customdate = false;

        this.datelabel = "เดือนนี้";

        this.fromDate = this.$moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        this.toDate = this.$moment()
          .endOf("month")
          .format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });
      } else if (daterange == 5) {
        this.customdate = true;
        this.datelabel = "กำหนดเอง";

        // this.fromDate = this.$moment().startOf('month').format('YYYY-MM-DD');
        // this.toDate = this.$moment().endOf('month').format('YYYY-MM-DD');
        //  this.$nextTick(() => {
        //    this.loadData();
        //})
        this.fromDate = null;
        this.toDate = null;

        console.log(this.fromDate);
        console.log(this.toDate);
      } else {
        this.fromDate = null;
        this.toDate = null;
      }

      // console.log(currentDateWithFormat);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loadData() {
      //   this.Loading = true;
      const params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      console.log("params :>> ", params);
      api
        .post("report_venue2", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.items = response.data.data;
          this.totalRows = response.data.total;
          console.log("this.totalRows :>> ", this.totalRows);
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fe718d;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
